import { Tabs } from "antd";
import { Ordenes } from "./ordenes/Ordenes.jsx";
import { Servicios } from "./servicios/Servicios.jsx";
import { Instrucciones } from "./instrucciones/Instrucciones.jsx";

export const Facturacion = () => {
  const items = [
    {
      key: "1",
      label: "Servicios Pendientes",
      children: <Servicios />,
    },
    {
      key: "2",
      label: "Ordenes de Pago Pendientes",
      children: <Ordenes />,
    },
    {
      key: "3",
      label: "Instrucciones de Facturación",
      children: <Instrucciones />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
