import { useEffect } from "react";
import { useSelector } from "react-redux";


export const useSound = () => {


    const { asistenciasPorConfirmar } = useSelector(
        (state) => state.asistenciasReducer
    );

    useEffect(() => {
        let audio = new Audio("/ding.mp3")
         audio.play()
    }, [asistenciasPorConfirmar.length])


    ///

}
