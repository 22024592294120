import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  FilePdfOutlined,
  PhoneOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { startLogoff } from "../context/actions/loginActions";
import { useValidaProveedorFacturacion } from "../hooks/useValidaProveedorFacturacion";

////
export default function MenuLayout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { canFactuacion } = useValidaProveedorFacturacion();

  const handleClick = ({ key }) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };

  return (
    <>
      <Menu
        onClick={handleClick}
        style={{ marginTop: 10 }}
        theme="light"
        defaultSelectedKeys={["0"]}
        mode="inline"
      >
        <Menu.Item key="/" icon={<PhoneOutlined />}>
          Asistencias
        </Menu.Item>
        {/* 
          {canFactuacion && (
            <Menu.Item key="/facturacion" icon={<FilePdfOutlined />}>
              Facturación
            </Menu.Item>
        )} */}

        <Menu.Item key="/facturacion" icon={<FilePdfOutlined />}>
          Facturación
        </Menu.Item>

        <Menu.Item key="/logoff" icon={<PoweroffOutlined />}>
          Cerrar sesión
        </Menu.Item>
      </Menu>
    </>
  );
}
